<template>
  <div class="container">
    <div class="wrapper">
      <div class="header">
        <img src="../assets/safety.svg" alt="" />
      </div>
      <br />
      <h3>Download PLD Delivery Schedule</h3>
      <a @click="downloadFile" class="btn"  style="display: table;margin: 35px auto;">Download Schedule</a>
    </div>
  </div>
</template>

<script>

export default {
    name: "PLD",
    data() {
        return {
            // ...
        };
    },
    methods: {
        downloadFile() {
            const url = process.env.VUE_APP_BASE_API_URL + '/pld';
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "PLD Delivery Schedule.xlsx");
            document.body.appendChild(link);
            link.click();
        },
        
  },
};
</script>
